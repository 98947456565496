<template>
    <div id="cliniques-index" class="page-wrapper" v-if="pageIsLoaded">
        <!-- Hero -->
        <section class="small-hero">
            <div class="col col-content">
                <h1 class="regular-title" data-inview="fadeInUp" data-delay="100">
                    {{ $t('Soins dentaires') }}
                </h1>
            </div>
            <div class="col col-img" data-inview="revealRight" data-delay="100">
                <img :src="content.image.url" :alt="content.image.titre" />
            </div>
        </section>

        <!-- Articles grid -->
        <section class="articles-grid">
            <svg
                class="particle"
                height="46"
                viewBox="0 0 315 80"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                data-inview="fadeInUp"
                data-delay="400"
            >
                <path
                    d="M0.247544 0C3.46562 1.89689 6.55992 3.9175 9.61297 5.89686C12.666 7.87623 15.9253 9.64941 18.9784 11.5463L28.3851 16.8246C31.5619 18.5566 34.7387 20.1236 37.9155 21.773C50.4423 28.06 63.419 33.408 76.7387 37.7729C102.837 46.2878 130.108 50.6706 157.562 50.7625C185.027 50.7192 212.314 46.3638 238.426 37.8554C251.762 33.4982 264.753 28.1499 277.291 21.8555C280.426 20.2473 283.644 18.639 286.821 16.9483L296.228 11.6288C299.322 9.73188 302.499 7.91746 305.593 6.02057C308.688 4.12368 311.782 1.89689 315 0.0824755C312.978 3.21647 310.874 6.22676 308.564 9.23705C306.253 12.2473 303.819 15.0514 301.385 17.8968C298.951 20.7421 296.228 23.34 293.587 26.1441C292.308 27.5462 290.864 28.7008 289.462 30.0204L285.336 33.8142C273.724 43.7532 260.935 52.2289 247.255 59.0511C245.564 59.9583 243.79 60.7006 242.057 61.5253L236.776 63.917C235.043 64.7418 233.228 65.3191 231.413 66.0613L226.008 68.1232C222.377 69.4428 218.705 70.5562 214.992 71.752C211.279 72.9479 207.525 73.7314 203.77 74.7211C188.646 78.2304 173.171 80.0014 157.644 79.9994C126.547 80.0766 95.8565 72.9337 67.9921 59.1336C54.3074 52.3205 41.5171 43.844 29.9116 33.8967L25.7859 30.1029C24.4244 28.8245 22.9391 27.6286 21.6601 26.2678C19.0196 23.505 16.3379 20.9071 13.8625 18.0205C11.387 15.1339 8.99411 12.2886 6.68369 9.36075C4.37328 6.43294 2.06287 3.29894 0 0.206181L0.247544 0Z"
                    fill="#BEE0D9"
                />
            </svg>
            <div class="grid">
                <article class="article-thumb intro">
                    <h2 class="small-title" data-inview="fadeInUp" data-delay="100">
                        {{ content.intro.titre }}
                    </h2>
                    <p class="regular-text" data-inview="fadeInUp" data-delay="300">
                        {{ content.intro.texte }}
                    </p>
                    <a
                        href="#"
                        class="site-btn alternate"
                        title="Prendre rendez-vous"
                        data-inview="fadeInUp"
                        data-delay="400"
                        @click.prevent="togglePanel('rendez-vous')"
                        >{{ content.intro.bouton.customText }}</a
                    >
                </article>
                <router-link
                    :to="item.jsonUrl"
                    class="article-thumb"
                    :title="item.title"
                    v-for="(item, i) in globals.traitementsItems.data"
                    :key="i"
                >
                    <div class="img-wrap" data-inview="revealRight" data-delay="100">
                        <img :src="item.image.url" :alt="item.image.titre" />
                    </div>
                    <div class="content">
                        <p class="regular-text title bold">{{ item.title }}</p>
                        <p class="small-text text">
                            {{ item.courtText }}
                        </p>
                        <p class="small-text time">
                            <svg
                                height="20"
                                viewBox="0 0 27 27"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M13.5 0.00029467C9.9194 0.00029467 6.48608 1.42242 3.9535 3.95376C1.42217 6.48622 0 9.91959 0 13.5001C0 17.0807 1.42214 20.514 3.9535 23.0465C6.48599 25.5779 9.9194 27 13.5 27C17.0806 27 20.5139 25.5779 23.0465 23.0465C25.5778 20.5141 27 17.0807 27 13.5001C26.9965 9.92077 25.5721 6.48954 23.0418 3.95817C20.5104 1.42804 17.0794 0.00353461 13.4997 0L13.5 0.00029467ZM13.5 24.5458C10.5706 24.5458 7.76091 23.3826 5.6896 21.3105C3.61736 19.2394 2.45422 16.4298 2.45422 13.5001C2.45422 10.5705 3.61748 7.76112 5.6896 5.68984C7.76067 3.61761 10.5704 2.45449 13.5 2.45449C16.4296 2.45449 19.2391 3.61773 21.3104 5.68984C23.3826 7.76088 24.5458 10.5705 24.5458 13.5001C24.5423 16.4284 23.3779 19.2359 21.3069 21.3069C19.2358 23.3779 16.4285 24.5423 13.5 24.5458ZM17.8388 16.104C18.0747 16.3329 18.2093 16.6471 18.2116 16.9761C18.2139 17.304 18.0839 17.6204 17.8514 17.8529C17.619 18.0853 17.3037 18.2142 16.9746 18.2119C16.6456 18.2096 16.3315 18.075 16.1037 17.8391L12.6335 14.3678H12.6324C12.4045 14.1388 12.2757 13.8293 12.2734 13.506L12.2722 13.5003V8.59068C12.2722 7.91298 12.8222 7.36414 13.4999 7.36414C14.1776 7.36414 14.7276 7.91298 14.7276 8.59068V12.9916L17.8388 16.104Z"
                                    fill="#565B6E"
                                />
                            </svg>
                            <span>{{ item.tempsApprox }}</span>
                        </p>
                        <svg
                            class="plus"
                            height="45"
                            viewBox="0 0 60 60"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <circle cx="30" cy="30" r="30" fill="#89AEAC" />
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M30 40C30.9266 40 31.6931 39.2335 31.6931 38.3069V31.6931H38.3069C39.2335 31.6931 40 30.9266 40 30C40 29.0734 39.2335 28.3069 38.3069 28.3069H31.6931V21.6931C31.6931 20.7665 30.9266 20 30 20C29.0734 20 28.3069 20.7665 28.3069 21.6931V28.3069H21.6931C20.7665 28.3069 20 29.0734 20 30C20 30.9266 20.7665 31.6931 21.6931 31.6931H28.3069V38.3069C28.3069 39.2335 29.0734 40 30 40V40Z"
                                fill="white"
                            />
                        </svg>
                    </div>
                </router-link>
            </div>
        </section>
    </div>
    <PageLoader v-else />
</template>

<script>
import { initInViewAnimations } from '@/plugins/InViewAnimations'

import { mapState, mapGetters, mapActions } from 'vuex'
import PageLoader from '@/components/PageLoader.vue'

export default {
    name: 'Traitements',

    components: {
        PageLoader,
    },

    watch: {
        pageIsLoaded: {
            handler(pageIsLoaded) {
                if (pageIsLoaded) {
                    // Page load buffer
                    setTimeout(() => {
                        initInViewAnimations()
                    }, 155)
                }
            },
            immediate: true,
        },
    },

    methods: {
        ...mapActions(['togglePanel']),
    },

    computed: {
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded']),
        ...mapGetters(['seo', 'content', 'globals']),
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.page-wrapper {
    min-height: 90vh;
}
</style>
